import AddIcon from "@mui/icons-material/Add";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import {
  CircularProgress,
  IconButton,
  TablePagination,
  TextField,
} from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import * as React from "react";
import { Link } from "react-router-dom";
import * as XLSX from "xlsx";
import Cloud from "../../assets/images/iconCloud.svg";
import Label from "../../components/Label";
import MainLayout from "../../layouts/MainLayout";
import api from "../../services/index";
import ModalDetails from "./components/modal";
import * as S from "./style";

export default function ListShop() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openElem, setOpenElem] = React.useState(null);
  const [modalIsOpen, setModalIsOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [shop, setShop] = React.useState();
  const [body, setBody] = React.useState({
    type: "",
    content: "",
    start: "",
    end: "",
  });

  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event, elemId) => {
    setAnchorEl(event.currentTarget);
    setOpenElem(elemId);
  };

  function openModalDetails(row) {
    setModalIsOpen(true);
    setAnchorEl(null);
  }

  async function getShop() {
    try {
      setLoading(true);
      const { data } = await api.post("v1/gateway/admin/customers/list", body);
      setShop(data.data);

      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  }

  function HandleExport() {
    setLoading(true);
    var wb = XLSX.utils.book_new(),
      ws = XLSX.utils.json_to_sheet(shop);

    XLSX.utils.book_append_sheet(wb, ws, "MySheet1");

    XLSX.writeFile(wb, "Compradores.xlsx");
    setLoading(false);
  }

  React.useEffect(() => {
    getShop();
  }, []);

  return (
    <MainLayout title="Cadastro de lojistas">
      <S.Header>
        <S.TimeFilter
          onChange={(e) => setBody({ ...body, start: e.target.value })}
        >
          <Label>Data Inicial</Label>
          <TextField
            id="date"
            type="date"
            inputProps={{
              style: {
                padding: 10,
                height: 20,
                marginRight: 10,
              },
            }}
          />
        </S.TimeFilter>
        <S.TimeFilter
          onChange={(e) => setBody({ ...body, end: e.target.value })}
        >
          <Label>Data Final</Label>
          <TextField
            id="date"
            type="date"
            inputProps={{
              style: {
                padding: 10,
                height: 20,
                marginRight: 10,
              },
            }}
          />
        </S.TimeFilter>
        <S.Select onChange={(e) => setBody({ ...body, type: e.target.value })}>
          <option value="" selected>
            Selecionar filtro
          </option>
          <option value="document">CPF/CNPJ</option>
          <option value="name">Nome</option>
          <option value="email">E-mail</option>
          <option value="phone">Telefone</option>
        </S.Select>
        <S.Input
          placeholder="Busque pelo nome"
          onChange={(e) => setBody({ ...body, content: e.target.value })}
        />
        <S.Button disabled={loading} onClick={() => getShop()}>
          Pesquisar
        </S.Button>
      </S.Header>

      <S.HeaderButtons>
        <S.ButtonHeader onClick={() => openModalDetails()}>
          <AddIcon></AddIcon>Novo Cadastro
        </S.ButtonHeader>

        <S.ButtonHeader disabled={loading} onClick={() => HandleExport()}>
          <S.ImgCloud src={Cloud} alt="icon"></S.ImgCloud>download csv
        </S.ButtonHeader>
      </S.HeaderButtons>
      <TableContainer component={Paper}>
        {loading ? (
          <S.ContainerLoading>
            <CircularProgress style={{ color: "#07254f" }} />
          </S.ContainerLoading>
        ) : (
          <Table stickyHeader sx={{ minWidth: 650 }} aria-label="simple table">
            <S.StyledTableHead>
              <TableRow>
                <TableCell>Data e Hora</TableCell>
                <TableCell>Nome</TableCell>
                <TableCell>CPF/CNPJ</TableCell>
                <TableCell>E-mail</TableCell>
                <TableCell>Telefone</TableCell>
                <TableCell>Cidade/Estado</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </S.StyledTableHead>
            {/* name, document, email, phone, city, total */}
            <TableBody>
              {shop?.map((shop) => (
                <TableRow
                  key={shop.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {shop.created_at}
                  </TableCell>
                  <TableCell>{shop.name}</TableCell>
                  <TableCell>{!!shop?.cnpj ? shop?.cnpj : shop?.cpf}</TableCell>
                  <TableCell>{shop.email}</TableCell>
                  <TableCell>{shop.phone}</TableCell>
                  <TableCell>{`${shop.address.city} / ${shop.address.state}`}</TableCell>
                  <TableCell>
                    <IconButton
                      aria-label="more"
                      id="long-button"
                      aria-controls={open ? "long-menu" : undefined}
                      aria-expanded={open ? "true" : undefined}
                      aria-haspopup="true"
                      onClick={(e) => handleClick(e, shop.customer_id)}
                      style={{ padding: 0 }}
                    >
                      <MoreVertOutlinedIcon />
                    </IconButton>
                    <Menu
                      id={"basic-menu" + shop.customer_id}
                      anchorEl={anchorEl}
                      open={openElem === shop.customer_id}
                      onClose={handleClose}
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                    >
                      <MenuItem>
                        {!!shop?.cnpj ? (
                          <Link
                            to={`/cadastro-lojista-pj`}
                            state={{ customer_id: shop.customer_id }}
                          >
                            Editar Cadastro
                          </Link>
                        ) : (
                          <Link
                            to={`/cadastro-lojista-pf/`}
                            state={{ customer_id: shop.customer_id }}
                          >
                            Editar Cadastro
                          </Link>
                        )}
                      </MenuItem>
                    </Menu>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </TableContainer>
      <ModalDetails setModalIsOpen={setModalIsOpen} modalIsOpen={modalIsOpen} />
    </MainLayout>
  );
}
